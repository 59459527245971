@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
body, html {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
#root {
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-center {
  text-align: center;
}
.overflow-dotted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}