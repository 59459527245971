@import url('https://fonts.googleapis.com/css2?family=Supermercado+One&display=swap');

.topnav-container {
    width: calc(100% - 90px);
    height: 70px;
    background: linear-gradient(90deg, #00E0FF 0%, #0070FF 100%);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 40px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.692);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
}
.topnav-container .logo {
    font-family: 'Supermercado One', cursive;
    font-size: 35px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.459);
}
.topnav-container .hamburger-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 10px;
}
.topnav-container .hamburger-container:hover {
    background-color: rgba(255, 255, 255, 0.17);
}
.topnav-container .hamburger-container i {
    font-size: 30px;
}
.topnav-container .wallet-details {
    font-size: 15px;
    line-height: 20px;
}
.topnav-container .wallet-details span {
    font-size: 13px;
}

/* Sidenav */
.sidenav {
    width: 270px;
    height: calc(100vh - 70px);
    position: fixed;
    top: 70px;
    background-color: #003E8D;
    padding-top: 30px;
    z-index: 3 !important;
    transition: all 0.3s;
}
.sidenav .each-el {
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 17px;
    background-color: rgba(0, 0, 0, 0.17);
    margin-bottom: 10px;
    transition: all 0.3s ease-out;
}
.sidenav .each-el:hover {
    background-color: rgba(0, 0, 0, 0.37);
    cursor: pointer;
}
.sidenav .each-el i {
    margin-right: 10px;
}
.sidenav .each-el.active {
    background-color: rgba(0, 0, 0, 0.45);
}
.sidebar-dark-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.274);
    z-index: 2;
}

/* reponsiveness */

@media screen and (min-width: 1570px) {
    .topnav-container {
        height: 100px;
        padding-left: 70px;
        padding-right: 60px;
        width: calc(100vw - 130px);
    }
    .topnav-container h1.logo {
        font-size: 45px;
    }
    .sidenav {
        width: 400px;
        top: 100px;
        height: calc(100vh - 100px);
    }
    .sidenav .each-el {
        font-size: 25px !important;
        height: 80px;
    }
}
@media (max-width: 960px) {
    .wallet-details {
        display: none;
    }
    .sidenav {
        transform: translateX(-300px);
    }
    .topnav-container {
        justify-content: flex-start;
    }
}
@media (min-width: 960px) {
    .topnav-container .hamburger-container {
        display: none !important;
    }
    .sidenav {
        transform: translateX(0px) !important;
    }
}
@media (max-width: 710px) {
    .topnav-container{
        padding: 0px 10px;
        width: calc(100% - 20px);
    }
}