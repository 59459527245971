@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Supermercado+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rambla&display=swap);
* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
body, html {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
#root {
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-center {
  text-align: center;
}
.overflow-dotted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.solid-loader-bg {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(131, 255, 245, 0.144); */
    background-color: rgb(202, 255, 251);
    z-index: 10 !important;
}
.light-loader-bg {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(131, 255, 245, 0.144);
    z-index: 10 !important;
}
.bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    margin: 0px 7px;
    position: relative;
    -webkit-animation: bubblefly 0.9s infinite;
            animation: bubblefly 0.9s infinite;
}
.bubble:first-child {
    background: rgb(255,150,12);
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
    background: linear-gradient(0deg, rgba(255,150,12,1) 0%, rgba(255,30,30,1) 100%);
}
.bubble:nth-child(2) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    background: linear-gradient(0deg, rgb(255, 251, 12) 0%, rgba(84,255,30,1) 100%);
}
.bubble:nth-child(3) {
    background: rgb(12,255,122);
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    background: linear-gradient(0deg, rgba(12,255,122,1) 0%, rgba(0,211,255,1) 100%);
}
.bubble:nth-child(4) {
    background: rgb(12,90,255);
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
    background: linear-gradient(0deg, rgba(12,90,255,1) 0%, rgba(128,0,255,1) 100%);
}
.bubble:nth-child(5) {
    background: rgb(255,12,254);
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    background: linear-gradient(0deg, rgba(255,12,254,1) 0%, rgba(0,172,255,1) 100%);
}
.bubble:last-child {
    background: rgb(255,12,12);
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
    background: linear-gradient(0deg, rgba(255,12,12,1) 0%, rgba(255,171,0,1) 100%);
}
@-webkit-keyframes bubblefly {
    0% {top: 0px;}
    30% {top: -40px;}
    60% {top: 0px;}
}
@keyframes bubblefly {
    0% {top: 0px;}
    30% {top: -40px;}
    60% {top: 0px;}
}
.main-content-container {
    margin-top: 70px;
    margin-left: 270px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}
a {
    text-decoration: none;
}
.main-content-container {
    padding-top: 50px;
}
.main-content-container h2 {
    font-size: 32px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.20);
    color: #002B6B;
}

/* Product details modal */

.modal-dark-bg {
    z-index: 10 !important;
    background-color: rgba(0, 0, 0, 0.603);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    -webkit-animation: bg-fade 0.3s 1 ease-in-out;
            animation: bg-fade 0.3s 1 ease-in-out;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    transition: all 0.3s;
}
@-webkit-keyframes bg-fade {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.603);
    }
}
@keyframes bg-fade {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.603);
    }
}
.product-details-modal {
    position: fixed;
    background-color: white;
    border-radius: 10px;
    width: 450px;
    height: 80vh;
    top: 50%;
    left: 50%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.712);
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    transition: all 0.2s;
    z-index: 11 !important;
    overflow-y: scroll;
    -webkit-animation: modal-animation 0.2s 1 ease-out;
            animation: modal-animation 0.2s 1 ease-out;
    max-height: 670px;
}
@-webkit-keyframes modal-animation {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0.3);
                transform: translate(-50%, -50%) scale(0.3);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
@keyframes modal-animation {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(0.3);
                transform: translate(-50%, -50%) scale(0.3);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
.product-details-modal::-webkit-scrollbar {
    display: none;
}
.product-details-modal .close-option {
    border: 1px solid black;
    background-color: black;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
}
.product-details-modal .close-option i {
    padding: 10px;
    cursor: pointer;
}
.product-details-modal h3 {
    font-size: 22px;
    margin-bottom: 20px;
}
.prod-modal-det-container {
    padding: 15px;
}
.prod-modal-det-container > div {
    line-height: 30px;
}
.prod-modal-det-container .qr-code {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.qr-code svg {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
.prod-modal-det-container .det-title {
    color: rgb(0, 23, 100);
    font-weight: 600;
}

/* dashboard */

.dashboard-search-container {
    margin-top: 40px;
    position: relative;
    margin-bottom: 20px;
}
.dashboard-search-container input {
    padding: 10px 20px;
    padding-right: 45px;
    font-size: 17px;
    outline: none;
    border-radius: 10px;
    width: 350px;
    border: 1px solid rgb(216, 216, 216);
    box-shadow: 2px 2px 5px rgb(180, 180, 180);
    transition: all 0.3s;
}
.dashboard-search-container input:focus {
    border: 1px solid #61dfff;
    box-shadow: 0px 0px 2px rgb(27, 27, 27);

}
.dashboard-search-container .search-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #63ACC3;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}
.scan-qr {
    background-color: #ff9409;
    height: 45px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.247);
    color: white;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s;
}
.scan-qr:hover {
    background-color: rgb(209, 87, 0);
}
.scan-qr i {
    margin-right: 10px;
    font-size: 18px;
}

/* Change ownership page */

.main-content-container.owned h2 {
    margin-bottom: 50px;
}
.owned-products-list .each-prod {
    width: 800px;
    height: 100px;
    background: linear-gradient(90deg, #F1FFFF 0%, rgba(241, 255, 255, 0) 100%);
    border: 1px solid #CAFFFF;
    box-shadow: 0px 4px 4px 0px #E7E7E7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.owned-prod-detail {
    width: calc(100% - 170px);
    cursor: pointer;
}
.owned-products-list .each-prod .transfer-btn {
    width: 120px;
    height: 45px;
    background: linear-gradient(180deg, #00E0FF 0%, #0070FF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.349);
    color: white;
    cursor: pointer;
    background-position: 0 100%;
    background-size: auto 100%;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.39);
    transition: all 0.3s !important;
}
.owned-products-list .each-prod .transfer-btn:hover {
    background-size: auto 200%;
    background-position: 100% 100%;
    box-shadow: 0px 0px 2px black;
}
.owned-prod-detail .prod-name {
    font-size: 18px;
    margin-bottom: 15px;
    color: #002B6B;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manu-id-row {
    display: flex;
    font-size: 15px;
}
.manu-id-row .manufacturer-name {
    margin-right: 40px;
    width: 250px;
    overflow: hidden;
}
.manu-id-row .prod-id {
    color: #004D15;
}
.recipient-input {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.recipient-input h4 {
    margin-bottom: 10px;
}
.recipient-input input {
    padding: 7px;
    font-size: 17px;
    border: none;
    outline: none;
    background-color: #b3b3b340;
    width: 300px;
    border-bottom: 2px solid #00858D;
    transition: all 0.2s;
}
.recipient-input input:focus {
    border-bottom: 2px solid #00e1ec;
}
.recipient-input button {
    background-color: #ff9409;
    border-radius: 10px;
    width: 140px;
    height: 40px;
    color: white;
    font-size: 16px;
    border: none;
    outline: none;
    transition: all 0.3s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.247);
    margin-top: 20px;
    cursor: pointer;
}
.recipient-input button:hover {
    background-color: rgb(209, 87, 0);
}
.recipient-input select {
    height: 30px;
    padding-left: 10px;
    border-radius: 5px;
    background: linear-gradient(0deg, rgb(221, 221, 221) 0%, rgba(255,255,255,1) 100%);
    font-size: 16px;
    border: 1px solid rgb(170, 170, 170);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.192);
}

/* Add product page */

.add-prod-inputs input {
    border: none !important;
    background: #F2F2F2 !important;
    width: 400px !important;
    border-radius: 0px !important;
    border-bottom: 2px solid #00858D !important;
    transition: all 0.2s;
}
.add-prod-inputs input:focus {
    border-bottom: 2px solid #00cad4 !important;
}
.add-prod-inputs.login-form > div {
    margin-bottom: 35px;
}
.add-prod-inputs.login-form > div > label {
    top: -13px;
    bottom: 10px;
    left: 5px;
    font-size: 14px;
    opacity: 1;
    height: 15px;
}
.add-prod-inputs button {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #F26600;
    border: none;
    outline: none;
    color: white;
    font-size: 17px;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    transition: all 0.2s;
}
.add-prod-inputs button:hover {
    background-color: rgb(209, 87, 0);
}
.toast-alert {
    position: absolute;
    top: 1px;
    left: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.158);
    color: white;
    z-index: 1;
    width: 370px;
    border-left: 7px solid rgb(0, 158, 0);
    color: rgb(0, 112, 0);
    background-color: rgb(244, 255, 244);
    -webkit-animation: toast-fade 0.3s 1 linear;
            animation: toast-fade 0.3s 1 linear;
}
@-webkit-keyframes toast-fade {
    0% {
        left: calc(50% + 40px);
        opacity: 0;
    }
    100% {
        left: 50%;
        opacity: 1;
    }
}
@keyframes toast-fade {
    0% {
        left: calc(50% + 40px);
        opacity: 0;
    }
    100% {
        left: 50%;
        opacity: 1;
    }
}
.add-prod-inputs.login-form label {
    z-index: 1 !important;
}

/* responsiveness */

@media screen and (min-width: 1570px) {
    .owned-products-list .each-prod {
        width: 900px;
    }
    .main-content-container {
        width: calc(100vw - 400px);
        margin-left: 400px;
        padding-top: 100px;
    }
    .main-content-container h2 {
        font-size: 36px;
    }
}
@media (max-width: 1180px) {
    .owned-products-list .each-prod {
        width: 600px;
        height: 80px;
    }
}
@media (max-width: 960px) {
    .main-content-container {
        width: 100%;
        margin-left: 0px;
    }
}
@media screen and (max-width: 710px) {
    .owned-products-list {
        width: calc(100% - 50px);
        margin: auto;
    }
    .owned-products-list .each-prod {
        width: calc(100% - 40px);
    }
}
.topnav-container {
    width: calc(100% - 90px);
    height: 70px;
    background: linear-gradient(90deg, #00E0FF 0%, #0070FF 100%);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 40px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.692);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
}
.topnav-container .logo {
    font-family: 'Supermercado One', cursive;
    font-size: 35px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.459);
}
.topnav-container .hamburger-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 10px;
}
.topnav-container .hamburger-container:hover {
    background-color: rgba(255, 255, 255, 0.17);
}
.topnav-container .hamburger-container i {
    font-size: 30px;
}
.topnav-container .wallet-details {
    font-size: 15px;
    line-height: 20px;
}
.topnav-container .wallet-details span {
    font-size: 13px;
}

/* Sidenav */
.sidenav {
    width: 270px;
    height: calc(100vh - 70px);
    position: fixed;
    top: 70px;
    background-color: #003E8D;
    padding-top: 30px;
    z-index: 3 !important;
    transition: all 0.3s;
}
.sidenav .each-el {
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 17px;
    background-color: rgba(0, 0, 0, 0.17);
    margin-bottom: 10px;
    transition: all 0.3s ease-out;
}
.sidenav .each-el:hover {
    background-color: rgba(0, 0, 0, 0.37);
    cursor: pointer;
}
.sidenav .each-el i {
    margin-right: 10px;
}
.sidenav .each-el.active {
    background-color: rgba(0, 0, 0, 0.45);
}
.sidebar-dark-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.274);
    z-index: 2;
}

/* reponsiveness */

@media screen and (min-width: 1570px) {
    .topnav-container {
        height: 100px;
        padding-left: 70px;
        padding-right: 60px;
        width: calc(100vw - 130px);
    }
    .topnav-container h1.logo {
        font-size: 45px;
    }
    .sidenav {
        width: 400px;
        top: 100px;
        height: calc(100vh - 100px);
    }
    .sidenav .each-el {
        font-size: 25px !important;
        height: 80px;
    }
}
@media (max-width: 960px) {
    .wallet-details {
        display: none;
    }
    .sidenav {
        -webkit-transform: translateX(-300px);
                transform: translateX(-300px);
    }
    .topnav-container {
        justify-content: flex-start;
    }
}
@media (min-width: 960px) {
    .topnav-container .hamburger-container {
        display: none !important;
    }
    .sidenav {
        -webkit-transform: translateX(0px) !important;
                transform: translateX(0px) !important;
    }
}
@media (max-width: 710px) {
    .topnav-container{
        padding: 0px 10px;
        width: calc(100% - 20px);
    }
}
.login-bg {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: rgb(0,112,255);
    background: linear-gradient(0deg, rgba(0,112,255,1) 0%, rgba(0,224,255,1) 100%);
    display: flex;
    justify-content: center;
}
.login-container {
    width: 420px;
    background: linear-gradient(123.24deg, rgba(255, 255, 255, 0.98) 4.6%, rgba(255, 255, 255, 0.69) 79.41%);
    align-self: center;
    margin: 50px 0px;
    border-radius: 15px;
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.5), 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.212);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    min-height: 400px;
}
.login-container h1 {
    margin-top: 40px;
    font-size: 37px;
    color: #000066;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.desig-choose {
    margin-top: 70px;
    transition: all 200ms;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.desig-choose > div {
    width: 270px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px rgb(247, 103, 0);
    margin-bottom: 30px;
    cursor: pointer;
    background: rgb(247, 103, 0);
    color: white;
    font-size: 18px;
    font-family: 'Rambla', sans-serif;
    border-radius: 10px;
    transition: all 0.3s;
}
.desig-choose > div:hover,
.login-form input[type="submit"]:hover {
    background-color: rgb(209, 87, 0);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.452);
}
.login-desig {
    color: rgb(247, 103, 0);
    font-weight: bold;
    font-family: 'Rambla', sans-serif;
}
a {
    color: black;
}
.login-form {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-form > div {
    position: relative;
    margin-bottom: 30px;
    height: 42px;
}
.login-form > div > input {
    width: 270px;
    display: block;
    border-radius: 5px;
    outline: none;
    /* border: 2px solid #999999; */
    border: 2px solid rgba(0, 0, 0, 0.473);
    height: 40px;
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 16px;
    background-color: transparent;
}
.login-form > div > label {
    position: absolute;
    z-index: 4 !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding-left: 10px;
    /* color: #0a4677; */
    color: black;
    font-weight: 600;
    pointer-events: none;
    transition: all 0.3s;
    left: 2px;
    background-color: transparent;
    padding-right: 10px;
}
#pass-req-info {
    position: absolute;
    top: 45px;
    font-size: 14px;
    left: 10px;
    color: rgb(255, 41, 41);
}
.login-form input[type="submit"] {
    background-color: rgb(247, 103, 0);
    color: white;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Rambla', sans-serif;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    outline: none;
    border: none;
    transition: all 0.3s;
    border-radius: 10px;
    width: 150px !important;
    height: 40px;
    /* padding-left: 0px !important; */
}
.login-form input[type="submit"]:focus,
.login-form input[type="submit"]:active {
    outline: none  !important;
    border: none !important;
    cursor: pointer;
}

.login-form > div > input:focus + label,
.login-form > div > input:not(:focus):valid + label,
.login-form > div > input:not(:focus):active + label {
    top: -13px;
    bottom: 10px;
    left: 5px;
    font-size: 14px;
    opacity: 1;
    height: 15px;
}
.login-form input:focus {
    cursor: text;
    border: 2px solid black;
    outline: none;
}
.register {
    min-height: 500px !important;
    padding-bottom: 50px;
}
.type-choose-radio {
    width: 100%;
    margin-bottom: 50px !important;
}
.type-choose-radio label {
    position: relative;
}
.type-choose-radio input {
    width: auto;
    height: auto;
    display: inline;
}
.type-choose-radio div {
    height: auto !important;
}
.type-choose-radio > div {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10px;
}
.type-choose-radio p {
    font-weight: 600;
    font-size: 18px;
}
.type-choose-radio input {
    width: 30px;
}
.type-choose-radio input,
.type-choose-radio label {
    cursor: pointer;
}
#owner-type {
    width: 100% !important;
    height: 40px;
    padding-left: 10px;
    border-radius: 5px;
    background: linear-gradient(0deg, rgb(221, 221, 221) 0%, rgba(255,255,255,1) 100%);
    font-size: 16px;
    border: 1px solid rgb(170, 170, 170);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.192);
    margin-bottom: 30px;
}
