@import url('https://fonts.googleapis.com/css2?family=Rambla&display=swap');

.login-bg {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: rgb(0,112,255);
    background: linear-gradient(0deg, rgba(0,112,255,1) 0%, rgba(0,224,255,1) 100%);
    display: flex;
    justify-content: center;
}
.login-container {
    width: 420px;
    background: linear-gradient(123.24deg, rgba(255, 255, 255, 0.98) 4.6%, rgba(255, 255, 255, 0.69) 79.41%);
    align-self: center;
    margin: 50px 0px;
    border-radius: 15px;
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.5), 
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.212);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    min-height: 400px;
}
.login-container h1 {
    margin-top: 40px;
    font-size: 37px;
    color: #000066;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.desig-choose {
    margin-top: 70px;
    transition: all 200ms;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.desig-choose > div {
    width: 270px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px rgb(247, 103, 0);
    margin-bottom: 30px;
    cursor: pointer;
    background: rgb(247, 103, 0);
    color: white;
    font-size: 18px;
    font-family: 'Rambla', sans-serif;
    border-radius: 10px;
    transition: all 0.3s;
}
.desig-choose > div:hover,
.login-form input[type="submit"]:hover {
    background-color: rgb(209, 87, 0);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.452);
}
.login-desig {
    color: rgb(247, 103, 0);
    font-weight: bold;
    font-family: 'Rambla', sans-serif;
}
a {
    color: black;
}
.login-form {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-form > div {
    position: relative;
    margin-bottom: 30px;
    height: 42px;
}
.login-form > div > input {
    width: 270px;
    display: block;
    border-radius: 5px;
    outline: none;
    /* border: 2px solid #999999; */
    border: 2px solid rgba(0, 0, 0, 0.473);
    height: 40px;
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 16px;
    background-color: transparent;
}
.login-form > div > label {
    position: absolute;
    z-index: 4 !important;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10px;
    /* color: #0a4677; */
    color: black;
    font-weight: 600;
    pointer-events: none;
    transition: all 0.3s;
    left: 2px;
    background-color: transparent;
    padding-right: 10px;
}
#pass-req-info {
    position: absolute;
    top: 45px;
    font-size: 14px;
    left: 10px;
    color: rgb(255, 41, 41);
}
.login-form input[type="submit"] {
    background-color: rgb(247, 103, 0);
    color: white;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Rambla', sans-serif;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    outline: none;
    border: none;
    transition: all 0.3s;
    border-radius: 10px;
    width: 150px !important;
    height: 40px;
    /* padding-left: 0px !important; */
}
.login-form input[type="submit"]:focus,
.login-form input[type="submit"]:active {
    outline: none  !important;
    border: none !important;
    cursor: pointer;
}

.login-form > div > input:focus + label,
.login-form > div > input:not(:focus):valid + label,
.login-form > div > input:not(:focus):active + label {
    top: -13px;
    bottom: 10px;
    left: 5px;
    font-size: 14px;
    opacity: 1;
    height: 15px;
}
.login-form input:focus {
    cursor: text;
    border: 2px solid black;
    outline: none;
}
.register {
    min-height: 500px !important;
    padding-bottom: 50px;
}
.type-choose-radio {
    width: 100%;
    margin-bottom: 50px !important;
}
.type-choose-radio label {
    position: relative;
}
.type-choose-radio input {
    width: auto;
    height: auto;
    display: inline;
}
.type-choose-radio div {
    height: auto !important;
}
.type-choose-radio > div {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10px;
}
.type-choose-radio p {
    font-weight: 600;
    font-size: 18px;
}
.type-choose-radio input {
    width: 30px;
}
.type-choose-radio input,
.type-choose-radio label {
    cursor: pointer;
}
#owner-type {
    width: 100% !important;
    height: 40px;
    padding-left: 10px;
    border-radius: 5px;
    background: linear-gradient(0deg, rgb(221, 221, 221) 0%, rgba(255,255,255,1) 100%);
    font-size: 16px;
    border: 1px solid rgb(170, 170, 170);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.192);
    margin-bottom: 30px;
}